//18、三孔3 D
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles3(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /**
         
   d = D*1,
      w= W*1,
      h= H*1,
        x0 = X*1,
        y0 = Y*1;
         */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
if(area==2){
    //画孔B位置孔
          //标记三孔3 B位置孔右上
          var myThHoles3BRightup= new makerjs.models.Holes(d/2, [[xb-w/2, yb-h]]);
          var myThHoles3MarkBRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkBRightup.origin = [xb-w/2, yb-h];      
          var myThHoles3WMarkBRightup = new makerjs.models.Square(0);      
          myThHoles3WMarkBRightup.origin =[xb-w/2, yb-h];   
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles3WMarkBRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    makerjs.model.addCaption(myThHoles3WMarkBRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔3B位置孔左上
     var myThHoles3BLeftup= new makerjs.models.Holes(d/2, [[xb+w/2, yb-h]]);
          var myThHoles3MarkBLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkBLeftup.origin = [xb+w/2, yb-h];       
          var myThHoles3HMarkBLeftup = new makerjs.models.Square(0);      
          myThHoles3HMarkBLeftup.origin = [xb+w/2, yb-h];
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkBLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkBLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3HMarkBLeftup, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
    makerjs.model.addCaption(myThHoles3HMarkBLeftup, TxtH,[0-markShift,0], [0-markShift, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔3 B位置孔右下
     var myThHoles3BRightdn= new makerjs.models.Holes(d/2, [[xb+w/2, yb]]);
          var myThHoles3MarkBRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkBRightdn.origin = [xb+w/2, yb];      
          var myThHoles3YMarkBRightdn = new makerjs.models.Square(0);      
          myThHoles3YMarkBRightdn.origin =[xb+w/2, yb]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkBRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkBRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3YMarkBRightdn, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
    makerjs.model.addCaption(myThHoles3YMarkBRightdn, TxtY,[0-markShift,0], [0-markShift, y0]);//Y文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles3BLeftup"+myHolesNum]=myThHoles3BLeftup;
    markers.models["myThHoles3BLeftup"+myHolesNum]=myThHoles3BLeftup;
    markers.models["myThHoles3MarkBLeftup"+myHolesNum]=myThHoles3MarkBLeftup;
    markers.models["myThHoles3HMarkBLeftup"+myHolesNum]=myThHoles3HMarkBLeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles3BRightup"+myHolesNum]=myThHoles3BRightup;
    markers.models["myThHoles3BRightup"+myHolesNum]=myThHoles3BRightup;
    markers.models["myThHoles3MarkBRightup"+myHolesNum]=myThHoles3MarkBRightup;
    markers.models["myThHoles3WMarkBRightup"+myHolesNum]=myThHoles3WMarkBRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles3BRightdn"+myHolesNum]=myThHoles3BRightdn;
    markers.models["myThHoles3BRightdn"+myHolesNum]=myThHoles3BRightdn;
    markers.models["myThHoles3MarkBRightdn"+myHolesNum]=myThHoles3MarkBRightdn;
    markers.models["myThHoles3YMarkBRightdn"+myHolesNum]=myThHoles3YMarkBRightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     
    }else if(area==8){
    //画孔H位置孔
          //标记三孔3 H位置孔左上
          var myThHoles3HLeftup= new makerjs.models.Holes(d/2, [[xh-w/2, yh+h]]);
          var myThHoles3MarkHLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkHLeftup.origin = [xh-w/2, yh+h];      
          var myThHoles3WMarkHLeftup = new makerjs.models.Square(0);      
          myThHoles3WMarkHLeftup.origin =[xh-w/2, yh+h];   
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkHLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkHLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles3WMarkHLeftup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    makerjs.model.addCaption(myThHoles3WMarkHLeftup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔3H位置孔右上
     var myThHoles3HRightup= new makerjs.models.Holes(d/2, [[xh+w/2, yh+h]]);
          var myThHoles3MarkHRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkHRightup.origin = [xh+w/2, yh+h];       
          var myThHoles3HMarkHRightup = new makerjs.models.Square(0);      
          myThHoles3HMarkHRightup.origin = [xh+w/2, yh+h]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles3HMarkHRightup, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
    makerjs.model.addCaption(myThHoles3HMarkHRightup, TxtH, [0-markShift,-h], [0-markShift, 0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔3 H位置孔左下
     var myThHoles3HLeftdn= new makerjs.models.Holes(d/2, [[xh+w/2, yh]]);
          var myThHoles3MarkHLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkHLeftdn.origin = [xh+w/2, yh];     
          var myThHoles3YMarkHLeftdn = new makerjs.models.Square(0);      
          myThHoles3YMarkHLeftdn.origin = [xh+w/2, yh]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkHLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkHLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3YMarkHLeftdn, new makerjs.paths.Line([0,-y0], [0, 0]));//Y短标线
    makerjs.model.addCaption(myThHoles3YMarkHLeftdn, TxtY, [0-markShift,-y0], [0-markShift, 0]);//Y文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles3HRightup"+myHolesNum]=myThHoles3HRightup;
    markers.models["myThHoles3HRightup"+myHolesNum]=myThHoles3HRightup;
    markers.models["myThHoles3MarkHRightup"+myHolesNum]=myThHoles3MarkHRightup;
    markers.models["myThHoles3HMarkHRightup"+myHolesNum]=myThHoles3HMarkHRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles3HLeftup"+myHolesNum]=myThHoles3HLeftup;
    markers.models["myThHoles3HLeftup"+myHolesNum]=myThHoles3HLeftup;
    markers.models["myThHoles3MarkHLeftup"+myHolesNum]=myThHoles3MarkHLeftup;
    markers.models["myThHoles3WMarkHLeftup"+myHolesNum]=myThHoles3WMarkHLeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles3HLeftdn"+myHolesNum]=myThHoles3HLeftdn;
    markers.models["myThHoles3HLeftdn"+myHolesNum]=myThHoles3HLeftdn;
    markers.models["myThHoles3MarkHLeftdn"+myHolesNum]=myThHoles3MarkHLeftdn;
    markers.models["myThHoles3YMarkHLeftdn"+myHolesNum]=myThHoles3YMarkHLeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
   
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default ThreeHoles3;