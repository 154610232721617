//16、三孔 C
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==2){
    //画孔B位置孔
          //标记三孔 B位置孔右上
          var myThHolesBRightup= new makerjs.models.Holes(d/2, [[xb-w/2, yb]]);
          var myThHolesMarkBRightup = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkBRightup.origin = [xb-w/2, yb];      
          var myThHolesWMarkBRightup = new makerjs.models.Square(0);      
          myThHolesWMarkBRightup.origin =[xb-w/2, yb];       
          var myThHolesYMarkBRightup = new makerjs.models.Square(0);      
          myThHolesYMarkBRightup.origin =[xb-w/2, yb];    
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkBRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHolesWMarkBRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    makerjs.model.addCaption(myThHolesWMarkBRightup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHolesYMarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
    makerjs.model.addCaption(myThHolesYMarkBRightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔B位置孔左下
     var myThHolesBLeftdn= new makerjs.models.Holes(d/2, [[xb+w/2, yb]]);
          var myThHolesMarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkBLeftdn.origin = [xb+w/2, yb];       
          var myThHolesHMarkBLeftdn = new makerjs.models.Square(0);      
          myThHolesHMarkBLeftdn.origin = [xb+w/2, yb];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkBLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHolesHMarkBLeftdn, new makerjs.paths.Line([0,-h], [0, 0]));//Y短标线
    makerjs.model.addCaption(myThHolesHMarkBLeftdn, TxtH, [0-markShift,-h], [0-markShift, 0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 B位置孔右下
     var myThHolesBRightdn= new makerjs.models.Holes(d/2, [[xb+w/2, yb-h]]);
          var myThHolesMarkBRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkBRightdn.origin = [xb+w/2, yb-h];
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkBRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkBRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHolesBLeftdn"+myHolesNum]=myThHolesBLeftdn;
    markers.models["myThHolesBLeftdn"+myHolesNum]=myThHolesBLeftdn;
    markers.models["myThHolesMarkBLeftdn"+myHolesNum]=myThHolesMarkBLeftdn;
    markers.models["myThHolesHMarkBLeftdn"+myHolesNum]=myThHolesHMarkBLeftdn;
    /*****************************************************************************/ 
    plate.models["myThHolesBRightup"+myHolesNum]=myThHolesBRightup;
    markers.models["myThHolesBRightup"+myHolesNum]=myThHolesBRightup;
    markers.models["myThHolesMarkBRightup"+myHolesNum]=myThHolesMarkBRightup;
    markers.models["myThHolesWMarkBRightup"+myHolesNum]=myThHolesWMarkBRightup;
    markers.models["myThHolesYMarkBRightup"+myHolesNum]=myThHolesYMarkBRightup;
    /*****************************************************************************/ 
    plate.models["myThHolesBRightdn"+myHolesNum]=myThHolesBRightdn;
    markers.models["myThHolesBRightdn"+myHolesNum]=myThHolesBRightdn;
    markers.models["myThHolesMarkBRightdn"+myHolesNum]=myThHolesMarkBRightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
  
    }else if(area==8){
    //画孔H位置孔
          //标记三孔 H位置孔左上
          var myThHolesHLeftup= new makerjs.models.Holes(d/2, [[xh-w/2, yh]]);
          var myThHolesMarkHLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkHLeftup.origin = [xh-w/2, yh];      
          var myThHolesYMarkHLeftup = new makerjs.models.Square(0);      
          myThHolesYMarkHLeftup.origin =[xh-w/2, yh]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkHLeftup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myThHolesMarkHLeftup, new makerjs.paths.Line([w,0], [0, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkHLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkHLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    /*****************************************************************************/   
    makerjs.model.addPath(myThHolesYMarkHLeftup, new makerjs.paths.Line([0,0], [0, -y0]));//X短标线
    makerjs.model.addCaption(myThHolesYMarkHLeftup, TxtY, [0-markShift, -y0], [0-markShift,0]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔H位置孔右下
     var myThHolesHRightdn= new makerjs.models.Holes(d/2, [[xh+w/2, yh]]);
          var myThHolesMarkHRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkHRightdn.origin = [xh+w/2, yh];       
          var myThHolesHMarkHRightdn = new makerjs.models.Square(0);      
          myThHolesHMarkHRightdn.origin = [xh+w/2, yh];        
          var myThHolesWMarkHRightdn = new makerjs.models.Square(0);      
          myThHolesWMarkHRightdn.origin = [xh+w/2, yh];  
    /*****************************************************************************/ 
    //makerjs.model.addPath(myThHolesMarkHRightdn, new makerjs.paths.Line([0,-h], [0, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkHRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkHRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    makerjs.model.addPath(myThHolesHMarkHRightdn, new makerjs.paths.Line([0,0], [0, h]));//Y短标线
    makerjs.model.addCaption(myThHolesHMarkHRightdn, TxtH, [0-markShift,0], [0-markShift, h]);//Y文字标注
    makerjs.model.addPath(myThHolesWMarkHRightdn, new makerjs.paths.Line([0,0], [-w, 0]));//X短标线
    makerjs.model.addCaption(myThHolesWMarkHRightdn, TxtW, [-w,0+markShift], [0, 0+markShift]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 F位置孔左下
     var myThHolesHLeftdn= new makerjs.models.Holes(d/2, [[xh+w/2, yh+h]]);
          var myThHolesMarkHLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkHLeftdn.origin = [xh+w/2, yh+h]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkHLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkHLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHolesHRightdn"+myHolesNum]=myThHolesHRightdn;
    markers.models["myThHolesHRightdn"+myHolesNum]=myThHolesHRightdn;
    markers.models["myThHolesMarkHRightdn"+myHolesNum]=myThHolesMarkHRightdn;
    markers.models["myThHolesHMarkHRightdn"+myHolesNum]=myThHolesHMarkHRightdn;
    markers.models["myThHolesWMarkHRightdn"+myHolesNum]=myThHolesWMarkHRightdn;
    /*****************************************************************************/ 
    plate.models["myThHolesHLeftup"+myHolesNum]=myThHolesHLeftup;
    markers.models["myThHolesHLeftup"+myHolesNum]=myThHolesHLeftup;
    markers.models["myThHolesMarkHLeftup"+myHolesNum]=myThHolesMarkHLeftup;
    markers.models["myThHolesYMarkHLeftup"+myHolesNum]=myThHolesYMarkHLeftup;
    /*****************************************************************************/ 
    plate.models["myThHolesHLeftdn"+myHolesNum]=myThHolesHLeftdn;
    markers.models["myThHolesHLeftdn"+myHolesNum]=myThHolesHLeftdn;
    markers.models["myThHolesMarkHLeftdn"+myHolesNum]=myThHolesMarkHLeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
  
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default ThreeHoles;