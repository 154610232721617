//19、三孔4 B
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles4(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
 if(area==2){
    //画孔B位置孔
          //标记三孔4 B位置孔右上
          var myThHoles4BRightup= new makerjs.models.Holes(d/2, [[xb-w/2, yb]]);
          var myThHoles4MarkBRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkBRightup.origin = [xb-w/2, yb];      
          var myThHoles4YMarkBRightup = new makerjs.models.Square(0);      
          myThHoles4YMarkBRightup.origin =[xb-w/2, yb];      
          var myThHoles4HMarkBRightup = new makerjs.models.Square(0);      
          myThHoles4HMarkBRightup.origin =[xb-w/2, yb];   
    /*****************************************************************************/ 
    
    makerjs.model.addPath(myThHoles4MarkBRightup, new makerjs.paths.Line([0,-h], [0, 0]));//直径标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles4YMarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
    makerjs.model.addCaption(myThHoles4YMarkBRightup, TxtY, [0-markShift,0], [0-markShift, y0]);//X文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles4HMarkBRightup, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
    makerjs.model.addCaption(myThHoles4HMarkBRightup, TxtH, [0-markShift,-h], [0-markShift, 0]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔4D位置孔左下
     var myThHoles4BLeftdn= new makerjs.models.Holes(d/2, [[xb-w/2, yb-h]]);
          var myThHoles4MarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkBLeftdn.origin = [xb-w/2, yb-h];       
          var myThHoles4WMarkBLeftdn = new makerjs.models.Square(0);      
          myThHoles4WMarkBLeftdn.origin = [xb-w/2, yb-h];
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkBLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkBLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles4WMarkBLeftdn, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    makerjs.model.addCaption(myThHoles4WMarkBLeftdn, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔4 B位置孔左上
     var myThHoles4BLeftup= new makerjs.models.Holes(d/2, [[xb+w/2, yb-h]]);
          var myThHoles4MarkBLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkBLeftup.origin = [xb+w/2, yb-h]; 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkBLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkBLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles4BLeftdn"+myHolesNum]=myThHoles4BLeftdn;
    markers.models["myThHoles4BLeftdn"+myHolesNum]=myThHoles4BLeftdn;
    markers.models["myThHoles4MarkBLeftdn"+myHolesNum]=myThHoles4MarkBLeftdn;
    markers.models["myThHoles4WMarkBLeftdn"+myHolesNum]=myThHoles4WMarkBLeftdn;
    /*****************************************************************************/ 
    plate.models["myThHoles4BRightup"+myHolesNum]=myThHoles4BRightup;
    markers.models["myThHoles4BRightup"+myHolesNum]=myThHoles4BRightup;
    markers.models["myThHoles4MarkBRightup"+myHolesNum]=myThHoles4MarkBRightup;
    markers.models["myThHoles4YMarkBRightup"+myHolesNum]=myThHoles4YMarkBRightup;
    markers.models["myThHoles4HMarkBRightup"+myHolesNum]=myThHoles4HMarkBRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles4BLeftup"+myHolesNum]=myThHoles4BLeftup;
    markers.models["myThHoles4BLeftup"+myHolesNum]=myThHoles4BLeftup;
    markers.models["myThHoles4MarkBLeftup"+myHolesNum]=myThHoles4MarkBLeftup;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
       
    }else if(area==8){
    //画孔H位置孔
          //标记三孔 H位置孔左上
          var myThHoles4HLeftup= new makerjs.models.Holes(d/2, [[xh-w/2, yh]]);
          var myThHoles4MarkHLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkHLeftup.origin = [xh-w/2, yh];      
          var myThHoles4YMarkHLeftup = new makerjs.models.Square(0);      
          myThHoles4YMarkHLeftup.origin =[xh-w/2, yh];     
          var myThHoles4HMarkHLeftup = new makerjs.models.Square(0);      
          myThHoles4HMarkHLeftup.origin =[xh-w/2, yh];   
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkHLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkHLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles4YMarkHLeftup, new makerjs.paths.Line([0,0], [0, -y0]));//X短标线
    makerjs.model.addCaption(myThHoles4YMarkHLeftup, TxtY, [0-markShift, -y0], [0-markShift,0]);//X文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles4HMarkHLeftup, new makerjs.paths.Line([0,0], [0, h]));//X短标线
    makerjs.model.addCaption(myThHoles4HMarkHLeftup, TxtH, [0-markShift,0], [0-markShift, h]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔H位置孔右下
     var myThHoles4HRightdn= new makerjs.models.Holes(d/2, [[xh-w/2, yh+h]]);
          var myThHoles4MarkHRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkHRightdn.origin = [xh-w/2, yh+h];       
          var myThHoles4WMarkHRightdn = new makerjs.models.Square(0);      
          myThHoles4WMarkHRightdn.origin = [xh-w/2, yh+h];  
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkHRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkHRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles4WMarkHRightdn, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
    makerjs.model.addCaption(myThHoles4WMarkHRightdn, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 H位置孔右上
     var myThHoles4HRightup= new makerjs.models.Holes(d/2, [[xh+w/2, yh+h]]);
          var myThHoles4MarkHRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkHRightup.origin = [xh+w/2, yh+h];
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkHRightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles4HRightdn"+myHolesNum]=myThHoles4HRightdn;
    markers.models["myThHoles4HRightdn"+myHolesNum]=myThHoles4HRightdn;
    markers.models["myThHoles4MarkHRightdn"+myHolesNum]=myThHoles4MarkHRightdn;
    markers.models["myThHoles4WMarkHRightdn"+myHolesNum]=myThHoles4WMarkHRightdn;
    /*****************************************************************************/ 
    plate.models["myThHoles4HLeftup"+myHolesNum]=myThHoles4HLeftup;
    markers.models["myThHoles4HLeftup"+myHolesNum]=myThHoles4HLeftup;
    markers.models["myThHoles4MarkHLeftup"+myHolesNum]=myThHoles4MarkHLeftup;
    markers.models["myThHoles4YMarkHLeftup"+myHolesNum]=myThHoles4YMarkHLeftup;
    markers.models["myThHoles4HMarkHLeftup"+myHolesNum]=myThHoles4HMarkHLeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles4HRightup"+myHolesNum]=myThHoles4HRightup;
    markers.models["myThHoles4HRightup"+myHolesNum]=myThHoles4HRightup;
    markers.models["myThHoles4MarkHRightup"+myHolesNum]=myThHoles4MarkHRightup;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
         
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default ThreeHoles4;